import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../providers/api.service';

@Component({
  selector: 'app-git',
  templateUrl: './git.component.html',
  styleUrls: ['./git.component.scss'],
})
export class GitComponent implements OnInit {

  username = '';
  followers = '';
  following = '';
  location = '';

  constructor(public apiService: ApiService) { }

  ngOnInit() {
    this.apiService.loadUsername().then((data) => {
      this.username = data.login;
      this.followers = data.followers;
      this.following = data.following;
      this.location = data.location;
    });
  }

}
