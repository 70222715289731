import { Component, OnInit } from '@angular/core';
import { DocumentViewer, DocumentViewerOptions } from '@ionic-native/document-viewer/ngx';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss'],
})
export class ResumeComponent implements OnInit {

  constructor(private document: DocumentViewer) { }

  ngOnInit() {
    const options: DocumentViewerOptions = {
      title: 'Samuel Perales'
    };

    this.document.viewDocument('../assets/sam.pdf', 'application/pdf', options);
  }

}
