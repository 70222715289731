import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  githubApiEndpoint = 'https://api.github.com';

  constructor(public http: HttpClientModule) {
    console.log('hello HttpService Provider');
  }

  loadUsername() {
    return $.get('https://api.github.com/users/sampjr');
  }

}
