import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { GitComponent } from '../app/pops/git/git.component';
// import { LinkedComponent } from '../app/pops/linked/linked.component';
import { EmailComponent } from '../app/pops/email/email.component';
import { HttpClientModule } from '@angular/common/http';
import { ResumeComponent } from '../app/pops/resume/resume.component';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';

@NgModule({
  declarations: [AppComponent, GitComponent, EmailComponent, ResumeComponent],
  entryComponents: [GitComponent, EmailComponent, ResumeComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule],
  providers: [
    StatusBar,
    SplashScreen,
    DocumentViewer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
