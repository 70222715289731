import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  {
    path: 'github',
    loadChildren: () => import('./modal/github/github.module').then( m => m.GithubPageModule)
  },
  {
    path: 'linkedin',
    loadChildren: () => import('./modal/linkedin/linkedin.module').then( m => m.LinkedinPageModule)
  },
  {
    path: 'email',
    loadChildren: () => import('./modal/email/email.module').then( m => m.EmailPageModule)
  },
  {
    path: 'insta',
    loadChildren: () => import('./modal/insta/insta.module').then( m => m.InstaPageModule)
  },
  {
    path: 'twitter',
    loadChildren: () => import('./modal/twitter/twitter.module').then( m => m.TwitterPageModule)
  },
  {
    path: 'resume',
    loadChildren: () => import('./modal/resume/resume.module').then( m => m.ResumePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
